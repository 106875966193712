import axios from 'axios';

import {
    setSession,
    removeSession,
    getAccessToken,
    getUserIdToken,
    setAccessToken,
    manageUserSession,
    // getCurrentLanguage,
    setAuthorizationToken,
} from './session';

/**
 * setup axios for ip address
 */

// const $axiosIP = axios.create({
//     baseURL: `"https://ipapi.co/json/"`,
//     timeout: 30000,
// });

const $axios = axios.create({
    // baseURL: `${process.env.REACT_APP_DOMAIN}${process.env.REACT_APP_VERSION}`,
    // baseURL: process.env.REACT_APP_DOMAIN,

    baseURL: 'https://rangapidev.appskeeper.com/api/rangl',
    // baseURL: 'https://rangapiqa.appskeeper.com/api/rangl',
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + sessionStorage.getItem('access_token'),
        api_key: '2581',
        // authorization: ''
    },
});

// const CancelToken = axios.CancelToken;

const api_error_code = {
    unauthorized: 401,
    accessDenied: 430,
    // sessionExpired: 440,
    sessionExpired: 419,
    validationError: 400,
    emailNotVerified: 403,
};

const api_success_code = {
    postSuccess: 201,
    success: 200,
};

const user_type = {
    SUB_ADMIN: 2,
    ADMIN: 1,
};

const table_actions = {
    SEARCH: 'search',
    CHANGE_PAGE: 'changePage',
    ON_SEARCH_CLOSE: 'onSearchClose',
    CHANGE_ROWS_PER_PAGE: 'changeRowsPerPage',
};

const paths = {
    LOGIN: '/',
    DASHBOARD: '/dashboard',
    MANAGEUSERS: '/manageUsers',
    NEWADMIN: '/newAdmin',
};

const expiryDaysForShare = 45;

const alert_messages = {
    INVITATION_SENT: '',
};

const page_limit = {
    limit: 6,
};
/**
 * constant variable for the website
 */
const constant = {
    paths,
    apiUrl: '',
    setSession,
    axios: $axios,
    table_actions,
    removeSession,
    getUserIdToken,
    getAccessToken,
    api_error_code,
    setAccessToken,
    api_success_code,
    manageUserSession,
    // getCurrentLanguage,
    userType: user_type,
    spaceRegex: /^\S*$/,
    alert_messages,
    numberRegex: /^\d+$/,
    setAuthorizationToken,
    ALGORITHM: 'aes-256-gcm',
    hasConnection: navigator.onLine,
    mainMenuLinksArray: ['/dashboard'],
    // axiosIP: $axiosIP,
    // eslint-disable-next-line
    passwordRegex: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z0-9!_@./#&+-\d]{8,}$/,
    onlyNumberRegex: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*._\b)$/,
    // eslint-disable-next-line
    emailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    page_limit,
    expiryDaysForShare,
};
export default constant;
